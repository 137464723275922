import React from "react";
import Project from "./SectionComponents/Project";

const ProjectsSection = () => {
  return (
    <>
      <div id="projects-section"></div>
      <div className="section-title-a">Projects</div>

      <section className="projects-section">
        <section className="projects-container">
          <Project
            title="Now There Be Goblins VR (2019-2022)"
            styling="ntbg"
            image="NTBG_Poster.png"
            link="https://store.steampowered.com/app/1378470/Now_There_Be_Goblins/"
          />
          <Project
            title="Koji's Quest VR (2020 - 2021)"
            styling="kq"
            image="KojisQuest_Poster.png"
            link="https://neuro-reality.com/products/"
          />
          {/* <Project
            title="Art & Programming VR (2020)"
            styling="vr"
            image="VR_Poster.gif"
            link="https://thomasvraudio.com/vrproject/"
          /> */}
          {/* <Project
            title="Lenli (2018) Student Project"
            styling="lenli"
            image="Lenli_Poster.png"
            link="https://thomasvraudio.com/lenli/"
          />
          <Project
            title="Nubbin (2018) Student Project"
            styling="nubbin"
            image="Nubbin_Poster.png"
            link="https://thomasvraudio.com/nubbin/"
          /> */}
        </section>
      </section>
    </>
  );
};

export default ProjectsSection;
