import React from "react";
import PlayList from "./SectionComponents/PlayList";

const MusicSection = () => {
  return (
    <>
      <div id="music-showreel-section"></div>
      <div className="section-title-a">Music Showreel</div>
      <section className="soundcloud-playlists">
        <PlayList
          source="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/815780391&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
          linkref="https://soundcloud.com/thomasvanrossum/sets/compilation"
        />
        <PlayList
          source="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1266067663&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
          linkref="https://soundcloud.com/thomasvanrossum/sets/kojis-quest-1"
        />
      </section>
    </>
  );
};

export default MusicSection;
