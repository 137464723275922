import React from "react";
import "../../styles/header.css";
import HeaderMenu from "./HeaderMenu";
import HeaderSocial from "./HeaderSocial";

const Header = () => {
  return (
    <header className="header-bar">
      <div className="header-menu">
        <HeaderMenu name={"Home"} link={"#name-section"} />
        <HeaderMenu name={"Projects"} link={"#projects-section"} />
        <HeaderMenu name={"Sound Design"} link={"#sd-videos-section"} />
        <HeaderMenu name={"Music"} link={"#music-showreel-section"} />
        <HeaderMenu name={"Biography"} link={"#biography-section"} />
        <HeaderMenu name={"Contact"} link={"#contact-section"} />
      </div>
      <div className="header-social-media">
        <HeaderSocial
          link="https://www.instagram.com/thomasvraudio/"
          image="instagram-icon.webp"
          name="Instagram"
        />
        <HeaderSocial
          link="https://soundcloud.com/thomasvanrossum"
          image="soundcloud-icon.webp"
          name="SoundCloud"
        />
        <HeaderSocial
          link="https://twitter.com/ThomasVRAudio"
          image="twitter-icon.webp"
          name="Twitter"
        />
      </div>
    </header>
  );
};

export default Header;
