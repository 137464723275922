import React from "react";
const BiographySection = () => {
  return (
    <>
      <div id="biography-section"></div>
      <div className="section-title-b">Biography</div>
      <section className="biography-container">
        <div className="biography-card">
        <div className="biography-text biography">
          <p className="hi-there-biography">Hi there!</p>
            <p>{`           
            I’m a Dutch Composer, Sound Designer & Game Developer. I've studied at the University of the Arts Utrecht (2016 - 2020), where I was able to work intensively with Game Developers.
            \nMy interest has grown a lot towards proper audio implementation, starting mainly in Virtual Reality (2020) with Unity and FMOD.           
            \nI love combining virtual orchestration with synthesizers and creative sound design, and tend to focus on interesting, complex harmonies wherever I can.`}
              </p>
            </div>
        </div>
      </section>
    </>
  );
};

export default BiographySection;
