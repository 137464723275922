import React from "react";

const Project = ({ title, link, image, styling }) => {
  return (
    <>
      <div className="project">
        <div className={`project-${styling}`}>
          <img src={require(`../../../images/${image}`)} alt={title} />
        </div>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <div className="black-cover"></div>
        </a>
        <div className="project-title">{title}</div>
      </div>
    </>
  );
};

export default Project;
