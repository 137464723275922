import React from "react";

const VideoSection = () => {
  return (
    <>
      <div id="video-section"></div>
      <section className="video-section">
        <iframe
          width="640"
          height="360"
          src="https://www.youtube.com/embed/KljOJl8gG5U?&autoplay=1&rel=0&loop=1&mute=1&playlist=KljOJl8gG5U&showinfo=0&controls=0&autohide=1&modestbranding=1"
          title="Now There Be Goblins - Early Access coming to Steam on March 28th 2022!"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; loop;"
          allowFullScreen
        ></iframe>
        <div className="yt-black-border"></div>
      </section>
    </>
  );
};

export default VideoSection;
