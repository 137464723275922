import React from "react";
import Header from "./components/header/Header";
import MobileHeaderButton from "./components/header/MobileHeaderButton";
import "./styles/general.css";
import Sections from "./components/Sections/Sections";
import { useMediaQuery } from "react-responsive";

function App() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  return (
    <>
      <div className="App">
        {isMobile ? <MobileHeaderButton /> : <Header />}
        <Sections />
      </div>
    </>
  );
}

export default App;
