import React from "react";
import "../../styles/sections.css";
import NameSection from "./NameSection";
import VideoSection from "./VideoSection";
import ProjectsSection from "./ProjectsSection";
import SDSection from "./SDSection";
import MusicSection from "./MusicSection";
import BiographySection from "./BiographySection";
import ContactSection from "./ContactSection";

const Sections = () => {
  return (
    <section className="sections">
      <NameSection />
      <VideoSection />
      <ProjectsSection />
      <SDSection />
      <MusicSection />
      <BiographySection />
      <ContactSection />
    </section>
  );
};

export default Sections;
