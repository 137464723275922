import React from "react";
import HeaderMenu from "./HeaderMenu";

const MobileHeaderMenu = ({ onNavOff }) => {
  return (
    <div className="mobile-header-menu">
      <HeaderMenu name={"Home"} link={"#name-section"} onNavOff={onNavOff} />
      <HeaderMenu
        name={"Projects"}
        link={"#projects-section"}
        onNavOff={onNavOff}
      />
      <HeaderMenu
        name={"Sound Design"}
        link={"#sd-videos-section"}
        onNavOff={onNavOff}
      />
      <HeaderMenu
        name={"Music"}
        link={"#music-showreel-section"}
        onNavOff={onNavOff}
      />
      <HeaderMenu
        name={"Biography"}
        link={"#biography-section"}
        onNavOff={onNavOff}
      />
      <HeaderMenu
        name={"Contact"}
        link={"#contact-section"}
        onNavOff={onNavOff}
      />
    </div>
  );
};

export default MobileHeaderMenu;
