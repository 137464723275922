import React from "react";

const NameSection = () => {
  return (
    <>
      <div id="name-section"></div>
      <section className="name-section">
        <div className="name">Thomas van Rossum</div>
        <div className="subtitle-name">Composer & Sound Designer for Games</div>
      </section>
    </>
  );
};

export default NameSection;
