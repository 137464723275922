import React, { useState } from "react";
import MobileHeaderMenu from "./MobileHeaderMenu";

const MobileHeader = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const handleMenuToggle = () => {
    setNavbarOpen(!navbarOpen);
  };

  return (
    <>
      <div className="mobile-header-button">
        <img
          src={require(`../../images/Mobile-Menu.png`)}
          alt={`Menu Icon`}
          onClick={handleMenuToggle}
        />
      </div>
      <div>
        {navbarOpen ? (
          <MobileHeaderMenu
            onNavOff={() => {
              setNavbarOpen(false);
            }}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default MobileHeader;
