import React from "react";
import SDVideo from "./SectionComponents/SDVideo";

const SDSection = () => {
  return (
    <>
      <section id="sd-videos-section"></section>
      <div className="section-title-b">Sound Design Showreel</div>
      <section className="sd-videos-section">
        <SDVideo video="https://www.youtube.com/embed/MTAxzouocn4?rel=0&modestbranding=1" />
        <SDVideo video="https://www.youtube.com/embed/z5GUetjL9qs?rel=0&modestbranding=1" />
        <SDVideo video="https://www.youtube.com/embed/tzv7l6ocN9Q?rel=0&modestbranding=1" />
        <SDVideo video="https://www.youtube.com/embed/DYghgHCOIQc?rel=0&modestbranding=1" />
      </section>
    </>
  );
};

export default SDSection;
