import React from "react";
import "../../styles/header.css";

const HeaderSocial = ({ image, link, name }) => {
  return (
    <div className="social-media-icons">
      <a href={link} target="_blank" rel="noopener noreferrer">
        <img src={require(`../../images/${image}`)} alt={`${name} Icon`} />
      </a>
    </div>
  );
};

export default HeaderSocial;
