import React from "react";

const ContactSection = () => {
  return (
    <>
      <div id="contact-section"></div>
      <section className="section-title-a">Contact Me</section>
      <section className="contact-container">
        <div className="contact-left-container">
          <div className="lets-connect">
            Let's Connect!
            <img
              src={require("../../images/handshake-icon.png")}
              alt="Handshake Icon"
            />
          </div>
          <div className="seen-something">
            Seen something you like? Feel free to contact me!
          </div>
        </div>
        <div className="contact-right-container">
          <div className="contact-right-container-line">
            <div>
              <img
                src={require("../../images/mail-icon.png")}
                alt="Mail Icon"
              />
            </div>
            <div>info@thomasvraudio.com</div>
          </div>
          <div className="contact-right-container-line">
            <div>
              <img
                src={require("../../images/phone-icon.png")}
                alt="Phone Icon"
              />
            </div>
            <div>+31 6 81 33 78 13</div>
          </div>
          <div className="contact-right-container-line">
            <div>
              <img
                src={require("../../images/home-icon.png")}
                alt="Home Icon"
              />
            </div>
            <div>Amsterdamsestraatweg, Utrecht 3553EL, NL</div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactSection;
